<template>
  <label for="idCard" class="upload-container">
    <input
      id="idCard"
      type="file"
      @change="handleFileUpload"
      accept=".xlsx, .xls, .csv"
    />
    <div class="upload-area">
      <img
        src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-cloud-upload-outline-512.png"
        alt="Upload Icon"
      />
      <p>Drag and drop or click to browse files</p>
    </div>
  </label>
  <span class="upload-note">
    <h5>Supported format: CSV, XLS, XLSX</h5>
    <h5>Maximum Size: 25MB</h5>
  </span>
  <div v-if="excelData.length" class="file-info">
    <span class="file-title">
      <ul>
        <img class="icon" src="../../../assets/images/excel.png" alt="excel" />
        <h5>
          <b> {{ fileName }}</b>
        </h5>
      </ul>
      <ul v-show="!isHide">
        <RecordIcon class="svg" />
        <h5>Total Records:</h5>
        <h5>
          <b> {{ excelData.length }}</b>
        </h5>
      </ul>
      <ul v-show="!isHide">
        <SizeIcon class="svg" />
        <h5>File size:</h5>
        <h5>
          <b> {{ fileSize }}</b>
        </h5>
      </ul>
    </span>
    <HeroProgressbar :progress="progress" v-if="isHide" />
  </div>

  <div class="submit-container" v-if="excelData.length">
    <button
      @click="matchingHandler()"
      class="submit-button"
      :disabled="isDisabled"
    >
      Proceed to match
    </button>
  </div>

  <div class="result-info" v-show="isDisabled">
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Records</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Total Match</td>
          <td>{{ matchList.length }}</td>
          <td>found.xlsx</td>
        </tr>
        <tr>
          <td>Total Unmatch</td>
          <td>{{ unmatchList.length }}</td>
          <td>not found.xlsx</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="submit-container" v-if="isDisabled">
    <button
      @click="completeHandler()"
      class="submit-button"
      :disabled="!matchList.length"
    >
      {{ matchList.length ? "Tag as Complete" : "No Match Found" }}
    </button>
  </div>
  <HeroProgressbar :progress="matchingProgress" v-if="isDisabled" />
</template>

<script>
import HeroProgressbar from "@/components/common/HeroProgressbar.vue";
import RecordIcon from "@/components/svgs/RecordIcon.vue";
import SizeIcon from "@/components/svgs/SizeIcon.vue";
import pincodeImage from "@/assets/images/pincode.png";
import * as XLSX from "xlsx";
import { computed, ref, inject } from "vue";

export default {
  components: {
    HeroProgressbar,
    RecordIcon,
    SizeIcon,
  },
  props: {
    dataItems: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const moment = inject("$moment");
    const swal = inject("$swal");
    const excelData = ref([]); // Data from Excel
    const dataArray = ref([]); // Your array of objects
    const fileName = ref("");
    const progress = ref(0);
    const matchingProgress = ref(0);
    const isHide = ref(true);
    const fileSize = ref(null);
    const matchList = ref([]);
    const unmatchList = ref([]);
    const isDisabled = ref(false);
    const patternCode = ref(null);

    const handleFileUpload = (event) => {
      isHide.value = true;
      progress.value = 0;
      unmatchList.value = [];
      matchList.value = [];
      const file = event.target.files[0];

      if (file) {
        const fileSizeInBytes = file.size;
        fileSize.value = `${(fileSizeInBytes / 1024).toFixed(2)}KB`; // KB

        fileName.value = file.name;
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);

          const totalRows = jsonData.length;
          let processedRows = 0;

          const filteredData = jsonData.map((row) => {
            const keys = Object.keys(row).slice(1, 2);
            return keys.reduce((obj, key) => {
              obj[key] = row[key];
              return obj;
            }, {});
          });

          excelData.value = filteredData;
          dataArray.value.push(...filteredData);

          const updateProgress = () => {
            if (processedRows < totalRows) {
              processedRows++;
              progress.value = (processedRows / totalRows) * 100;
              requestAnimationFrame(updateProgress);
              isHide.value = true;
            } else {
              progress.value = 100;
              setTimeout(() => {
                isHide.value = false;
              }, 1000);
            }
          };

          isDisabled.value = false;

          updateProgress();
        };

        reader.readAsArrayBuffer(file);
      }
    };

    const dataExcelMod = computed(() => {
      return excelData.value.map((item) => ({
        referenceNumber: item.Rrn
          ? typeof item.Rrn === "string"
            ? item.Rrn.split("-").pop()
            : ""
          : "",
      }));
    });

    const matchingHandler = () => {
      isDisabled.value = true;
      let pendingItems = props.dataItems;
      dataExcelMod.value.forEach((val) => {
        let isMatched = false;

        pendingItems.forEach((item) => {
          if (val.referenceNumber === item.reference) {
            matchList.value.push(item.id);
            isMatched = true;
          }
        });

        if (!isMatched) {
          unmatchList.value.push(val);
        }
      });
    };

    const completeHandler = () => {
      confirmAlert();
    };

    const confirmAlert = () => {
      const getRandomAlphaNum = () => Math.random().toString(36).slice(2, 8);
      patternCode.value = getRandomAlphaNum();

      swal
        .fire({
          imageUrl: pincodeImage,
          html: `<p>Please re-type the confirmation code below to proceed</p> <h2>${patternCode.value}</h2>`,
          input: "text",
          inputPlaceholder: "Re-type confirmation code",
          showCancelButton: true,
          confirmButtonText: "Submit",
          cancelButtonText: "Cancel",
          inputAttributes: {
            oncopy: "return false;",
            oncut: "return false;",
            onpaste: "return false;",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const inputCode = result.value;
            if (inputCode === patternCode.value) {
              processMatching();
            } else {
              swal.fire({
                title: "Invalid Confirmation Code",
                text: `${inputCode}`,
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          }
        });
    };
    const processMatching = async () => {
      try {
        let totalItems = matchList.value.length;
        let completedItems = 0;
        matchingProgress.value = 0; // Reset progress

        for (let i = 0; i < totalItems; i++) {
          let id = matchList.value[i];

          let result = await axios.post(
            `/portal/meta/api/auth/production?id=${id}`
          );
          if (result) {
            await axios.put(`/portal/meta/api/clients/${id}`, {
              status: "complete",
              datecompleted: moment(),
            });
          }

          // Update the progress after each item is completed
          completedItems++;
          matchingProgress.value = (completedItems / totalItems) * 100;
        }
        swal
          .fire({
            title: "Success",
            text: "All items have been successfully completed.",
            icon: "success",
            confirmButtonText: "Done",
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              emit("modalHandler", true);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      excelData,
      dataArray,
      handleFileUpload,
      matchingHandler,
      completeHandler,
      dataExcelMod,
      fileName,
      progress,
      matchingProgress,
      isHide,
      fileSize,
      matchList,
      unmatchList,
      isDisabled,
      confirmAlert,
      processMatching,
      patternCode,
    };
  },
};
</script>

<style scoped>
.bulk-import {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 500px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.upload-container {
  position: relative;
  width: 100%;
}
.upload-note {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
}
.upload-note h5 {
  color: #9c9c9c;
  font-weight: 600;
  font-size: 12px;
}
input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 2px dashed #7c7c7c;
  border-radius: 10px;
  background-color: #f0f8ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, border-color 0.3s;
  text-align: center;
}

.upload-area img {
  width: 60px;
  height: 60px;
}

.upload-area p {
  margin: 10px 0 0;
  color: #333;
  font-size: 16px;
}

.upload-container:hover .upload-area {
  background-color: #e0f7ff;
  border-color: #0056b3;
}

.file-info {
  margin-top: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 8px;
}
.file-info .file-title {
  margin: 12px 0px;
}
.file-info .file-title ul {
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 3px 0px;
}
.file-info .file-title ul h5 {
  font-weight: 400;
  font-size: 14px;
}
.icon {
  height: 32px;
  width: 32px;
}
.submit-container {
  margin-top: 20px;
  display: flex;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:active {
  transform: scale(0.98);
}
.svg {
  height: 22px;
  width: 22px;
}

.result-info {
  margin-top: 20px;
  border-radius: 8px;
  padding: 8px;
}
.result-info h3 {
  text-align: left;
  padding: 4px 6px;
}
.result-info ul {
  display: flex;
  gap: 5px;
  padding: 4px 6px;
}
</style>
