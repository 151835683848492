<template>
  <div class="action-btn">
    <select v-model="PageSize" @change="fetchTransaction()">
      <option v-for="(item, index) in filterLimit" :key="index" :value="item">
        {{ item }}
      </option>
    </select>
  </div>
  <div class="table-container" v-if="isRenderData">
    <table>
      <thead>
        <tr>
          <th>Transaction #</th>
          <th>Fullname</th>
          <th>Amount Paid</th>
          <th>Payment Date</th>
          <th>Reference Number</th>
          <th>Date of Tagging</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in transactionItems" :key="index">
          <td>{{ item.referenceNumber }}</td>
          <td>{{ item.membername }}</td>
          <td>{{ convertAmountFormat(item.premium) }}</td>
          <td>{{ convertDateFormat(item.createdAt) }}</td>
          <td>{{ item.reference }}</td>
          <td>{{ convertDateTimeFormat(item.datecompleted) }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">Total Amount</td>
          <td id="total-amount">{{ convertAmountFormat(totalAmount) }}</td>
          <td colspan="3" />
        </tr>
      </tfoot>
    </table>
    <PaginationGrid
      :totalPages="totalPages"
      :currentPage="currentPage"
      @update:currentPage="handlePageChange"
    />
  </div>
  <fetch-loading v-else />
</template>

<script>
import { onMounted, inject, ref, toRefs, watch, computed } from "vue";
import UsedFormatUtils from "@/utils/format";
import UsedPaginationUtils from "@/utils/pagination";
import PaginationGrid from "@/components/common/HeroPagination.vue";
export default {
  components: {
    PaginationGrid,
  },
  props: {
    search: {
      type: String,
    },
  },
  setup(props) {
    const axios = inject("$axios");
    const transactionItems = ref([]);
    const isRenderData = ref(false);
    const { convertDateFormat, convertDateTimeFormat, convertAmountFormat } =
      UsedFormatUtils();
    const { filterLimit, paginationItem, totalPages } = UsedPaginationUtils();
    onMounted(() => {
      fetchTransaction(paginationItem.currentPage);
    });
    const fetchTransaction = async (val) => {
      try {
        isRenderData.value = false;
        let search = !props.search ? "" : props.search;
        let status = "fraud";
        const { data } = await axios.get(`portal/transaction/pos`, {
          params: {
            search: search,
            status: status,
            pageSize: paginationItem.PageSize,
            page: val - 1,
          },
        });

        transactionItems.value = data
          .sort((a, b) => b.id - a.id)
          .map((item) => ({
            ...item,
            selectedStatus: "",
          }));
        fetchTransactionCount();
        isRenderData.value = true;
      } catch (error) {
        console.log(error);
      }
    };
    const fetchTransactionCount = async () => {
      try {
        const search = props.search || "";
        const status = "fraud";
        const { data } = await axios.get(`portal/transaction/pos/count`, {
          params: {
            search: search,
            status: status,
          },
        });
        paginationItem.totalItems = data.total;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    const checkStatusHandler = (val) => {
      return val === "paid" ? "pending" : val;
    };
    watch(() => props.search, fetchTransaction);

    const totalAmount = computed(() => {
      return transactionItems.value.reduce(
        (acc, item) => acc + parseFloat(item.premium),
        0
      );
    });
    const handlePageChange = (page) => {
      paginationItem.currentPage = page;
      fetchTransaction(page);
    };

    return {
      fetchTransaction,
      transactionItems,
      checkStatusHandler,
      isRenderData,
      convertDateFormat,
      convertDateTimeFormat,
      convertAmountFormat,
      totalAmount,
      fetchTransactionCount,
      handlePageChange,
      filterLimit,
      totalPages,
      ...toRefs(paginationItem),
    };
  },
};
</script>
