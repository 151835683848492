<template>
  <nav aria-label="Pagination">
    <ul class="pagination">
      <!-- Previous Button -->
      <li :class="{ disabled: currentPage === 1 }">
        <button
          @click="changePage(currentPage - 1)"
          :disabled="currentPage === 1"
        >
          Previous
        </button>
      </li>

      <!-- Page Numbers -->
      <li
        v-for="page in paginatedPages"
        :key="page"
        :class="{ active: page === currentPage }"
      >
        <button @click="changePage(page)" :disabled="page === currentPage">
          {{ page }}
        </button>
      </li>

      <!-- Next Button -->
      <li :class="{ disabled: currentPage === totalPages }">
        <button
          @click="changePage(currentPage + 1)"
          :disabled="currentPage === totalPages"
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed, defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { totalPages, currentPage } = toRefs(props);

    const paginatedPages = computed(() => {
      const pages = [];
      const range = 2; // Number of pages to show before and after the current page

      // Show pages from 1 to the total pages if within range
      for (let i = 1; i <= totalPages.value; i++) {
        if (
          i <= range ||
          i > totalPages.value - range ||
          (i >= currentPage.value - range && i <= currentPage.value + range)
        ) {
          pages.push(i);
        } else if (pages[pages.length - 1] !== "...") {
          pages.push("...");
        }
      }

      return pages;
    });

    const changePage = (page) => {
      if (page > 0 && page <= totalPages.value) {
        emit("update:currentPage", page);
      }
    };

    return {
      paginatedPages,
      changePage,
    };
  },
});
</script>
<style scoped>
.pagination {
  margin: 20px 0px;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
}

.pagination li {
  margin: 0;
}

.pagination button {
  border: 1px solid #ddd;
  background-color: white;
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.pagination button:hover {
  background-color: #f0f0f0;
  border-color: #007bff;
}

.pagination .active button {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination .disabled button {
  cursor: not-allowed;
  opacity: 0.6;
}

.pagination .disabled button:hover {
  background-color: white; /* Prevent hover effect on disabled buttons */
  border-color: #ddd;
}
</style>
