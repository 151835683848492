<template>
  <DashboardCard />
</template>
<script>
import DashboardCard from "@/components/partials/Dashboard/DashboardCard.vue";
export default {
  components: {
    DashboardCard,
  },
  setup() {},
};
</script>
