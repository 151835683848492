import { ref, reactive, computed } from "vue";

export default () => {
  const filterLimit = ref([10, 25, 50, 100]);
  const paginationItem = reactive({
    currentPage: 1,
    PageSize: 10,
    totalItems: 0,
  });
  const totalPages = computed(() =>
    Math.ceil(paginationItem.totalItems / paginationItem.PageSize)
  );

  return {
    filterLimit,
    paginationItem,
    totalPages,
  };
};
