import axios from "@/utils/axios";
import store from "../store";
export default () => {
  const fetchAllPOS = async () => {
    try {
      const status = "";
      const { data } = await axios.get(`portal/transaction/pos/count`, {
        params: {
          status: status,
        },
      });
      let count = data.total;
      store.commit("fetchTotalCountAll", count);
    } catch (error) {
      console.log(error);
      console.error("Error fetching transactions:", error);
    }
  };
  const fetchPendingPOS = async () => {
    try {
      const status = "paid";
      const { data } = await axios.get(`portal/transaction/pos/count`, {
        params: {
          status: status,
        },
      });
      let count = data.total;
      store.commit("fetchTotalCountPending", count);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  const fetchCompletePOS = async () => {
    try {
      const status = "complete";
      const { data } = await axios.get(`portal/transaction/pos/count`, {
        params: {
          status: status,
        },
      });
      let count = data.total;
      store.commit("fetchTotalCountComplete", count);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  const fetchFraudPOS = async () => {
    try {
      const status = "fraud";
      const { data } = await axios.get(`portal/transaction/pos/count`, {
        params: {
          status: status,
        },
      });
      let count = data.total;
      store.commit("fetchTotalCountFraud", count);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  return {
    fetchAllPOS,
    fetchPendingPOS,
    fetchCompletePOS,
    fetchFraudPOS,
  };
};
