<template>
  <div class="dashboard-grid">
    <div class="card-one">
      <div class="grid-info">
        <h1>Total Number of Pending Transactions</h1>
        <div class="card-count">0</div>
      </div>
    </div>
    <div class="card-two">
      <div class="grid-info">
        <h1>Total Pending POS Transactions</h1>
        <div class="card-count" v-if="isRenderData">{{ pos }}</div>
        <fetch-loading v-else />
      </div>
    </div>
    <div class="card-three">
      <div class="grid-info">
        <h1>Total Pending Online Transactions</h1>
        <div class="card-count">0</div>
      </div>
    </div>
    <div class="card-four">
      <div class="grid-info">
        <h1>Total Pending Check Transactions</h1>
        <div class="card-count">0</div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, onMounted, reactive, ref, toRefs } from "vue";
import UsedItemCount from "@/utils/itemcount";
export default {
  setup() {
    const { fetchAllPOS, fetchPendingPOS, fetchCompletePOS, fetchFraudPOS } =
      UsedItemCount();
    const axios = inject("$axios");
    const isRenderData = ref(false);
    const totalCountItems = reactive({
      pos: 0,
    });
    onMounted(() => {
      fetchTransactionCount();
      fetchAllPOS();
      fetchPendingPOS();
      fetchCompletePOS();
      fetchFraudPOS();
    });
    const fetchTransactionCount = async () => {
      try {
        isRenderData.value = false;
        const status = "paid";
        const { data } = await axios.get(`portal/transaction/pos/count`, {
          params: {
            status: status,
          },
        });
        totalCountItems.pos = data.total;
        isRenderData.value = true;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    return {
      fetchTransactionCount,
      isRenderData,
      ...toRefs(totalCountItems),
      fetchAllPOS,
      fetchPendingPOS,
      fetchCompletePOS,
      fetchFraudPOS,
    };
  },
};
</script>
<style scoped>
.dashboard-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}
.card-one,
.card-two,
.card-three,
.card-four {
  height: 240px;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  font-family: "Montserrat";
}
.grid-info {
  padding: 30px 20px 0px 20px;
}
.grid-info h1 {
  font-size: 24px;
  text-align: center;
}
.card-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  font-size: 34px;
}
@media only screen and (max-width: 600px) {
  .dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
