import axios from "@/utils/axios";
import router from "@/router";
export default () => {
  const logoutHandler = async () => {
    await axios.post(`/portal/auth/logout`);
    return router.push("/");
  };
  return {
    logoutHandler,
  };
};
