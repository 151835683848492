import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    // Set the Authorization header
    config.headers["x-token"] = localStorage.getItem(
      `${process.env.VUE_APP_AUTH_TOKEN}_XXX`
    );

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
