import { createRouter, createWebHashHistory } from "vue-router";
import UseLoginIndex from "../pages/LoginIndex.vue";
import UseDashboardIndex from "../pages/DashboardIndex.vue";
import UsePOSIndex from "../pages/POSIndex.vue";
import axios from "@/utils/axios";
const routes = [
  {
    path: "/",
    name: "login",
    component: UseLoginIndex,
    meta: {
      title: "Login",
      layout: "General",
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: UseDashboardIndex,
    meta: {
      title: "Dashboard",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/pos-transaction",
    name: "pos",
    component: UsePOSIndex,
    meta: {
      title: "POS Transaction",
      layout: "Account",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = `IMS DEPED MANAGEMENT PORTAL`;
  let isAuthenticate;
  try {
    let { data } = await axios.get(`/authorization`);
    isAuthenticate = data.authorized;
    console.log(isAuthenticate);
  } catch (error) {
    console.log(error);
  }
  if (to.path === "/admin/auth/logout") {
    await axios.post(`/portal/auth/logout`);
    next({
      path: "/",
    });
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticate) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && isAuthenticate) {
      next({ path: "/dashboard" });
    } else {
      next();
    }
  }
});

export default router;
