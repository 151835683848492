<template>
  <div class="action-btn">
    <select v-model="PageSize" @change="fetchTransaction()">
      <option v-for="(item, index) in filterLimit" :key="index" :value="item">
        {{ item }}
      </option>
    </select>
    <input
      class="import-btn"
      type="button"
      value="Import CSV File"
      @click="isModalOpen = true"
    />
  </div>
  <div class="table-container" v-if="isRenderData">
    <table>
      <thead>
        <tr>
          <th>Transaction #</th>
          <th>Fullname</th>
          <th>Amount Paid</th>
          <th>Payment Date</th>
          <th>Reference Number</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in transactionItems" :key="index">
          <td>{{ item.referenceNumber }}</td>
          <td>{{ item.membername }}</td>
          <td>{{ convertAmountFormat(item.premium) }}</td>
          <td>{{ convertDateFormat(item.createdAt) }}</td>
          <td>{{ item.reference }}</td>
          <td>
            <select
              class="action-status"
              v-model="item.selectedStatus"
              @change="selectedHandler(item.id, index, item.selectedStatus)"
            >
              <option disabled value="">--Please select--</option>
              <option
                v-for="status in itemStatus"
                :key="status"
                :value="status"
              >
                {{ status }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">Total Amount</td>
          <td id="total-amount">{{ convertAmountFormat(totalAmount) }}</td>
          <td colspan="3" />
        </tr>
      </tfoot>
    </table>
    <PaginationGrid
      :totalPages="totalPages"
      :currentPage="currentPage"
      @update:currentPage="handlePageChange"
    />
    <div>
      <HeroModal
        :modelValue="isModalOpen"
        @modalHandler="isModalOpen = val"
        title="Import Paymaya CSV"
      >
        <template #body>
          <ImporFile
            :dataItems="transactionItems"
            @modalHandler="(isModalOpen = val), (fromImportFile = val)"
          />
        </template>
      </HeroModal>
    </div>
  </div>
  <fetch-loading v-else />
</template>

<script>
import { onMounted, inject, ref, watch, computed, toRefs } from "vue";
import HeroModal from "@/components/common/HeroModal.vue";
import PaginationGrid from "@/components/common/HeroPagination.vue";
import ImporFile from "@/components/partials/POS/ImportFile.vue";
import UsedFormatUtils from "@/utils/format";
import UsedPaginationUtils from "@/utils/pagination";
import UsedItemCount from "@/utils/itemcount";
import pincodeImage from "@/assets/images/pincode.png";

export default {
  components: {
    HeroModal,
    ImporFile,
    PaginationGrid,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
    getItemImport: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal Title",
    },
  },
  setup(props) {
    const axios = inject("$axios");
    const swal = inject("$swal");
    const moment = inject("$moment");
    const transactionItems = ref([]);
    const isRenderData = ref(false);
    const isModalOpen = ref(false);
    const fromImportFile = ref(false);
    const itemStatus = ref(["complete", "fraud"]);
    const getSelectedId = ref();
    const patternCode = ref();
    const { convertDateFormat, convertAmountFormat } = UsedFormatUtils();
    const { filterLimit, paginationItem, totalPages } = UsedPaginationUtils();
    const { fetchAllPOS, fetchPendingPOS, fetchCompletePOS, fetchFraudPOS } =
      UsedItemCount();

    onMounted(() => {
      fetchTransaction(paginationItem.currentPage);
    });

    // Fetch transaction data from API
    const fetchTransaction = async (val) => {
      try {
        isRenderData.value = false;
        const search = props.search || "";
        const status = "paid";
        const { data } = await axios.get(`portal/transaction/pos`, {
          params: {
            search: search,
            status: status,
            pageSize: paginationItem.PageSize,
            page: val - 1,
          },
        });

        transactionItems.value = data
          .sort((a, b) => b.id - a.id)
          .map((item) => ({
            ...item,
            selectedStatus: "",
          }));
        fetchTransactionCount();
        isRenderData.value = true;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    const fetchTransactionCount = async () => {
      try {
        const search = props.search || "";
        const status = "paid";
        const { data } = await axios.get(`portal/transaction/pos/count`, {
          params: {
            search: search,
            status: status,
          },
        });
        paginationItem.totalItems = data.total;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    watch(() => props.search, fetchTransaction);
    watch(() => fromImportFile.value, fetchTransaction);

    const selectedHandler = (id, index, value) => {
      getSelectedId.value = id;
      confirmAlert(index, value);
    };
    const confirmAlert = (index, value) => {
      const getRandomAlphaNum = () => Math.random().toString(36).slice(2, 8);
      patternCode.value = getRandomAlphaNum();

      swal
        .fire({
          imageUrl: pincodeImage,
          html: `<p>Please re-type the confirmation code below to proceed</p> <h2>${patternCode.value}</h2>`,
          input: "text",
          inputPlaceholder: "Re-type confirmation code",
          showCancelButton: true,
          confirmButtonText: "Submit",
          cancelButtonText: "Cancel",
          inputAttributes: {
            oncopy: "return false;",
            oncut: "return false;",
            onpaste: "return false;",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const inputCode = result.value;
            if (inputCode === patternCode.value) {
              completeHandler(value);
              transactionItems.value[index].selectedStatus = "";
            } else {
              swal.fire({
                title: "Invalid Confirmation Code",
                text: `${inputCode}`,
                icon: "error",
                cancelButtonText: "Ok",
              });
              transactionItems.value[index].selectedStatus = "";
            }
          } else {
            transactionItems.value[index].selectedStatus = "";
          }
        });
    };

    const completeHandler = async (value) => {
      try {
        let id = getSelectedId.value;
        let result = await axios.post(
          `/portal/meta/api/auth/production?id=${id}`
        );
        if (result) {
          await axios.put(`/portal/meta/api/clients/${id}`, {
            status: value,
            datecompleted: moment(),
          });
        }
        swal
          .fire({
            title: "Success",
            text: "successfully completed.",
            icon: "success",
            confirmButtonText: "Done",
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let data = transactionItems.value;
              transactionItems.value = data.filter((item) => item.id !== id);
              fetchAllPOS();
              fetchPendingPOS();
              fetchCompletePOS();
              fetchFraudPOS();
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    const totalAmount = computed(() => {
      return transactionItems.value.reduce(
        (acc, item) => acc + parseFloat(item.premium),
        0
      );
    });

    const handlePageChange = (page) => {
      paginationItem.currentPage = page;
      fetchTransaction(page);
    };
    return {
      transactionItems,
      isRenderData,
      isModalOpen,
      fromImportFile,
      convertDateFormat,
      convertAmountFormat,
      itemStatus,
      getSelectedId,
      selectedHandler,
      confirmAlert,
      patternCode,
      completeHandler,
      fetchTransaction,
      totalAmount,
      fetchTransactionCount,
      handlePageChange,
      filterLimit,
      totalPages,
      ...toRefs(paginationItem),
      fetchAllPOS,
      fetchPendingPOS,
      fetchCompletePOS,
      fetchFraudPOS,
    };
  },
};
</script>
<style scoped>
.import-btn {
  background-color: var(--base-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.import-btn:hover {
  background-color: var(--hover-color);
}

.import-btn:active {
  background-color: var(--active-color);
  transform: scale(0.98);
}

.import-btn:focus {
  outline: none;
  box-shadow: 0 0 5px var(--primary-color);
}
</style>
