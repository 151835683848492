<template>
  <div class="progress-bar">
    <div class="progress-bar-inner" :style="{ width: `${progress}%` }"></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin: 10px 0px;
}

.progress-bar-inner {
  height: 10px;
  background-color: #099654;
  transition: width 0.3s ease;
}
</style>
