<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-container" @click.stop>
      <div class="modal-header">
        <h3>{{ title }}</h3>
        <CloseIcon @click="closeModal" class="icon" />
      </div>
      <div class="modal-body">
        <slot name="body">This is default modal content</slot>
      </div>
      <div class="modal-footer">
        <slot name="footer"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import CloseIcon from "@/components/svgs/CloseIcon.vue";
export default {
  components: {
    CloseIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal Title",
    },
  },

  setup(props, { emit }) {
    const isVisible = ref(props.modelValue);
    watch(
      () => props.modelValue,
      (newValue) => {
        isVisible.value = newValue;
      }
    );
    const closeModal = () => {
      emit("modalHandler", false);
    };

    return {
      isVisible,
      closeModal,
    };
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 580px;
  text-align: center;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  margin-top: 20px;
}

.modal-footer {
  margin-top: 20px;
}

body.modal-open {
  overflow: hidden;
}
.icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
</style>
