<template>
  <div class="wrapper">
    <div class="form-container">
      <div class="logo">
        <img src="../assets/images/ims-logo.png" alt="icon" />
      </div>
      <div class="top-header">
        <h2>IMS DEPED Management Portal</h2>
        <form @submit.prevent="submitHandler()">
          <div class="input-field">
            <input
              class="input"
              type="text"
              placeholder="username"
              v-model="username"
              required
            />
          </div>
          <div class="input-field">
            <input
              class="input"
              type="password"
              placeholder="password"
              v-model="password"
              autocomplete="on"
              required
            />
          </div>
          <div class="input-field">
            <input type="submit" class="submit" value="Login" />
          </div>
          <div class="alert error" v-show="isAlert">
            {{ alertMessage }}
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, reactive, ref, toRefs } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const router = inject("$router");
    const isAlert = ref(false);
    const alertMessage = ref(null);
    const formData = reactive({
      username: "acctg",
      password: "imsdeped",
    });

    const submitHandler = async () => {
      try {
        let result = await axios.post(`/portal/auth`, formData);
        if (result.status === 201) {
          console.log(result);
          localStorage.setItem(
            `${process.env.VUE_APP_AUTH_TOKEN}_XXX`,
            result.data.data
          );
          return router.push("/dashboard");
        } else {
          isAlert.value = true;
          alertMessage.value = result.data.message;
        }
        setTimeout(() => {
          isAlert.value = false;
        }, 3000);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      ...toRefs(formData),
      formData,
      submitHandler,
      isAlert,
      alertMessage,
    };
  },
};
</script>
<style scoped>
.wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container {
  min-width: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  height: 140px;
}
.input-field {
  margin: 10px 0px;
}
.input-field .input {
  height: 50px;
  border: none;
  min-width: 240px;
  border-radius: 30px;
  color: var(--lightFont);
  font-size: 15px;
  padding: 0 0 0 48px;
  background: rgba(255, 255, 255, 1);
  outline: none;
}
span {
  color: var(--lightFont);
  font-size: small;
  display: flex;
  justify-content: center;
  padding: 10px 0 10px 0;
}
.top-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.top-header h2 {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
  padding: 28px 0 30px 0;
}
.alert {
  margin-top: 25px;
  border-radius: 10px;
  padding: 12px;
}
.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}
.title-footer {
  margin: 50px 0px 30px 0px;
}
.submit {
  border: none;
  border-radius: 15px;
  font-size: 15px;
  height: 45px;
  outline: none;
  width: 100%;
  color: rgb(255, 255, 255);
  background: var(--primary-color);
  cursor: pointer;
  transition: 0.3s;
}
::-webkit-input-placeholder {
  color: var(--lightFont);
}
</style>
