import { createApp } from "vue";
import axios from "./utils/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import swal from "sweetalert2";
import "./assets/css/global.css";
import "./assets/css/table.css";
import HeroFetchLoading from "@/components/common/HeroFetchLoading.vue";

const app = createApp(App);

app.use(store);
app.use(router);
app.provide("$axios", axios);
app.provide("$router", router);
app.provide("$store", store);
app.provide("$moment", moment);
app.provide("$swal", swal);
app.component("fetch-loading", HeroFetchLoading);
app.mount("#app");
