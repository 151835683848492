<template>
  <HeroFilter
    @selected="getvalue"
    @searchEngine="getSearch"
    @eventHandler="getEventHandler"
  />
  <section>
    <component
      :is="ActiveComponent"
      :search="searchValue"
      :getItemImport="importValue"
    ></component>
  </section>
</template>
<script>
import HeroFilter from "@/components/common/HeroFilter.vue";
import TransactionAll from "@/components/partials/POS/TransactionAll.vue";
import TransactionPending from "@/components/partials/POS/TransactionPending.vue";
import TransactionComplete from "@/components/partials/POS/TransactionCompleted.vue";
import TransactionFraud from "@/components/partials/POS/TransactionFraud.vue";
import { computed, ref } from "vue";
export default {
  components: {
    HeroFilter,
    TransactionAll,
    TransactionPending,
    TransactionComplete,
    TransactionFraud,
  },
  setup() {
    const selectedComponent = ref("Pending");
    const searchValue = ref(null);
    const importValue = ref([]);
    const ActiveComponent = computed(() => {
      return `Transaction${selectedComponent.value}`;
    });
    const getvalue = (val) => {
      selectedComponent.value = val;
    };
    const getSearch = (val) => {
      console.log("Search Value", val);
      searchValue.value = val;
    };
    const getImportData = (val) => {
      importValue.value = val;
    };
    return {
      selectedComponent,
      ActiveComponent,
      getvalue,
      getSearch,
      searchValue,
      getImportData,
      importValue,
    };
  },
};
</script>
<style scoped>
section {
  margin: 30px 0px;
}
</style>
