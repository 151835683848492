<template>
  <div class="wrapper">
    <HeroSidebar />
    <main class="content">
      <h2>{{ $route.meta.title }}</h2>
      <router-view class="route" />
    </main>
  </div>
</template>

<script>
import HeroSidebar from "@/components/common/HeroSidebar.vue";
export default {
  components: {
    HeroSidebar,
  },
  setup() {},
};
</script>

<style scoped>
.wrapper {
  display: flex;
  min-height: 100vh;
}
.content {
  flex: 1;
  padding: 40px 20px 20px 20px;
}
.route {
  margin: 24px 0px;
}
</style>
