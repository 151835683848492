<template>
  <aside class="sidebar">
    <div class="logo">
      <img :src="require('@/assets/images/anec-logo.png')" alt="anec-logo" />
    </div>
    <nav class="nav">
      <ul>
        <li v-for="(item, index) in itemCategories" :key="index">
          <router-link v-if="item.path" :to="item.path" class="nav-menu">
            <component :is="item.icon" class="icon"></component>
            {{ item.name }}
          </router-link>
          <a v-else href="#" @click="item.action">
            {{ item.name }}
          </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>
<script>
import { reactive } from "vue";
import UsedLogoutUtils from "@/utils/logout";
import DashboardIcon from "@/components/svgs/DashboardIcon.vue";
import TerminalIcon from "@/components/svgs/TerminalIcon.vue";
import OnlineIcon from "@/components/svgs/OnlineIcon.vue";
export default {
  components: {
    DashboardIcon,
    TerminalIcon,
    OnlineIcon,
  },
  setup() {
    const { logoutHandler } = UsedLogoutUtils();
    const itemCategories = reactive([
      {
        name: "Dashboard",
        icon: "DashboardIcon",
        path: "/dashboard",
      },
      {
        name: "POS Transaction",
        icon: "TerminalIcon",
        path: "/pos-transaction",
      },
      {
        name: "Check Transaction",
        icon: "DashboardIcon",
        path: "/check-transaction",
      },
      {
        name: "Online Transaction",
        icon: "OnlineIcon",
        path: "/online-transaction",
      },
      {
        name: "Reports",
        icon: "DashboardIcon",
        path: "/reports",
      },
      {
        name: "Logout",
        icon: "DashboardIcon",
        path: null,
        action: logoutHandler,
      },
    ]);

    return {
      itemCategories,
      logoutHandler,
    };
  },
};
</script>

<style scoped>
.sidebar {
  width: 250px; /* Fixed width */
  background-color: var(--base-color);
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sidebar .logo {
  margin: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .logo img {
  height: 90px;
}
.nav ul {
  list-style: none;
  padding: 0;
}

.nav ul li {
  margin: 18px 0;
}

.nav ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.nav ul li a:hover {
  color: var(--hover-color);
}
.nav-menu {
  display: flex;
  align-items: center;
  gap: 5px;
}
.icon {
  height: 24px;
  width: 24px;
}
</style>
