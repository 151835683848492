<template>
  <div class="container">
    <div class="status-items">
      <ul>
        <li
          @click="
            activeItem = item;
            $emit('selected', item);
          "
          v-for="(item, index) in itemStatus"
          :key="index"
          :class="{ active: item === activeItem }"
        >
          {{ item }} ({{ statusCounts[item.toLowerCase()] }})
        </li>
      </ul>
    </div>
    <input
      type="text"
      v-model="search"
      placeholder="Search"
      @input="searchHandler()"
    />
  </div>
</template>
<script>
import { reactive, ref, computed, inject } from "vue";
export default {
  setup(props, { emit }) {
    const store = inject("$store");
    const activeItem = ref("Pending");
    const search = ref(null);
    const itemStatus = reactive(["All", "Pending", "Complete", "Fraud"]);
    const searchHandler = () => {
      console.log("Output", search.value);
      emit("searchEngine", search.value);
    };
    const statusCounts = computed(() => ({
      all: store.state.totalCountItems.all,
      pending: store.state.totalCountItems.pending,
      complete: store.state.totalCountItems.complete,
      fraud: store.state.totalCountItems.fraud,
    }));

    return {
      activeItem,
      itemStatus,
      searchHandler,
      statusCounts,
      search,
    };
  },
};
</script>
<style scoped>
.container {
  margin: 20px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
input {
  min-width: 280px;
  height: 25px;
  padding: 3px 10px;
}
.status-items {
  display: flex;
  align-items: center;
  background-color: rgb(218, 218, 218);
  height: 30px;
  padding: 10px;
  border-radius: 12px;
}
.status-items ul {
  display: flex;
  gap: 10px;
}
.status-items ul > li {
  margin: 0px 10px;
  color: rgb(121, 121, 121);
}
.status-items ul > li:hover {
  color: var(--hover-color);
}
.active {
  color: var(--primary-color) !important;
  font-weight: 700;
}
</style>
