import { createStore } from "vuex";
import persistedStateVuex from "persisted-state-vuex";
export default createStore({
  state: {
    totalCountItems: {
      all: 0,
      pending: 0,
      complete: 0,
      fraud: 0,
    },
  },
  getters: {},
  mutations: {
    fetchTotalCountAll(state, payLoad) {
      state.totalCountItems.all = payLoad ? payLoad : 0;
    },
    fetchTotalCountPending(state, payLoad) {
      state.totalCountItems.pending = payLoad ? payLoad : 0;
    },
    fetchTotalCountComplete(state, payLoad) {
      state.totalCountItems.complete = payLoad ? payLoad : 0;
    },
    fetchTotalCountFraud(state, payLoad) {
      state.totalCountItems.fraud = payLoad ? payLoad : 0;
    },
  },
  actions: {},
  modules: {},
  plugins: [persistedStateVuex.init],
});
