<template>
  <component :is="isLayout"></component>
</template>
<script>
import GeneralLayout from "@/layout/GeneralLayout.vue";
import AccountLayout from "@/layout/AccountLayout.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  components: {
    GeneralLayout,
    AccountLayout,
  },
  setup() {
    const route = useRoute();
    const isLayout = computed(() => {
      let routes = route.meta.layout;
      return `${routes}Layout`;
    });
    const layoutName = computed(() => {
      return route.name;
    });
    return {
      isLayout,
      layoutName,
    };
  },
};
</script>
